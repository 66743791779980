import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import VueElementLoading from "vue-element-loading";
// import 'viewerjs/dist/viewer.css'
// import Viewer from 'v-viewer'
// Vue.use(Viewer)
import AOS from 'aos'
import 'aos/dist/aos.css'
Vue.component("VueElementLoading", VueElementLoading);

Vue.config.productionTip = false


axios.defaults.baseURL = 'https://poacher.wti.org.in/';
Vue.prototype.baseURL = "https://poacher.wti.org.in/"
Vue.prototype.mediaURLnew = "https://poacher.wti.org.in/file/get/"

new Vue({
    vuetify,
    router,
    render: h => h(App),
    mounted() {
        AOS.init()
    },
}).$mount('#app')