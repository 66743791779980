<template>
  <v-app>
    <v-main>
      <v-layout fill-height wrap style="background-color: white">
        <v-flex xs12>
          <v-dialog
        v-model="queryPopUpDialog"
        persistent
        :width="
          $vuetify.breakpoint.name == 'xs'
            ? '100vw'
            : $vuetify.breakpoint.name == 'sm'
            ? '60vw'
            : $vuetify.breakpoint.name == 'md'
            ? '50vw'
            : $vuetify.breakpoint.name == 'lg'
            ? '40vw'
            : '30vw'
        "
      >
        <v-card tile color="#FFFFFF">
          <v-layout wrap justify-center pa-2>
            <v-flex xs12>
              <QueryPopUp @stepper="winStepper2" />
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
          <!-- <Header /> -->

          <router-view> </router-view>
          <!-- <Footer/> -->
        </v-flex>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
import "./assets/style/fonts.css";
import "./assets/style/style.css";
import QueryPopUp from "./components/Common/queryPopUp";

// import Header from './components/HeaderPage';
// import Footer from './components/FooterPage';

export default {
  name: "App",

  components: {
    QueryPopUp,
    // Header,
    // Footer,
  },

  data: () => ({
    
    queryPopUpDialog: false,
    //
  }),
  beforeMount() {
      if (this.getCookie("LookingFor") == "false") {
        return;
      } else {
        setTimeout(() => {
          this.queryPopUpDialog = true;
        }, 10000);
      }
    },
    methods:{
      winStepper2(windowData) {
      this.queryPopUpDialog = windowData.dialog;
      if (windowData.msg) {
        this.msg = windowData.msg;
        this.showSnackBar = true;
      }
    },
      getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
    },
    }
};
</script>
