<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FF1313" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap px-4 justify-end style="background-color: white">
      <v-flex xs2 sm2 md1 lg1 pl-lg-3 text-right>
        <v-btn small :ripple="false" text @click="closeLookingFor">
          <v-icon style="color: #red">mdi-close</v-icon>
        </v-btn>
      </v-flex>
      <v-flex text-left align-self-center>
        <v-layout wrap justify-center>
          <v-flex xs12>
            <v-img
              width="100%"
              height="100%"
              src="./../../assets/Images/popup.jpg"
              alt="Query Pop Up"
            >
              <v-layout wrap justify-end>
                <v-flex xs3 pr-4 pt-3>
                  <v-img contain src="../../assets/Images/logo12.jpeg"></v-img>
                </v-flex>
              </v-layout>
            </v-img>
          </v-flex>
          <v-flex xs12 pt-4 text-center>
            <span
              style="font-size: 18px; font-family: mainfont; font-weight: bold"
              >Your Support will benefit wildlife
              <span style="font-size: 19px">Conservation & Protection</span>
              efforts in the southern western ghats</span
            >
          </v-flex>
          <v-flex xs12 py-4 text-center>
            <v-btn
              color="#3d673a"
              dark
              target="_blank"
              href="https://donation.wti.org.in/wccd"
              ><span
                style="
                  font-size: 14px;
                  font-family: mainfont;
                  font-weight: bold;
                "
                >Support WTI</span
              ></v-btn
            >
          </v-flex>
          <!-- <v-flex xs12 pb-5 text-center>
              <span class="popupbottom">{{ user.bottomtext }}</span>
            </v-flex> -->
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
// import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      msg: null,
      timeout: 5000,
      name: null,
      email: null,
      phone: null,
      user: [],
      lookingfor: null,
    };
  },
  mounted() {},
  methods: {
    closeLookingFor() {
      var d = new Date();
      let exp = 1 * 24 * 60 * 60 * 1000;
      d.setTime(d.getTime() + exp);
      var expDate = d.toUTCString();
      document.cookie =
        "LookingFor=" + "false" + ";expires=" + expDate + ";path=/";
      this.queryPopUpDialog = false;
      this.$emit("stepper", {
        dialog: false,
        msg: this.msg,
      });
    },
  },
};
</script>
  <style>
.popuptitle {
  color: #000000;
  font-family: poppinsbold;
}
.popuptop {
  color: #000000;
  font-family: poppinsmedium;
}
.popupbottom {
  color: #000000;
  font-family: poppinsmedium;
}
.subscribeinput .v-input__append-inner {
  margin-top: 0px !important;
}
.subscribeinput .v-input__slot {
  padding-right: 0px !important;
}

.subscribeinput.v-text-field--outlined,
.v-text-field--solo {
  border-radius: 0px !important;
}
.subscribeinput.v-text-field--outlined {
  border-radius: 0px !important;
}
</style>
  